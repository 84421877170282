/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  width: 98.33333333%;
  max-width: 1180px;
}
#home {
  margin: 16px 5px 0 0;
}
.mood {
  margin-top: 18px;
}
@media (min-width: 1001px) {
  .mood {
    min-height: 580px;
  }
}
.mood__desk {
  padding-bottom: 49.15254237%;
}
@media (min-width: 1001px) {
  .mood__desk {
    height: 580px;
    padding: 0;
  }
}
.cb-plugin-ganzboats--legend-item {
  width: 20%;
}
h1,
.unit h2,
h5 {
  font-size: 24px;
  font-size: 2.4rem;
}
.footer {
  margin-top: 30px;
}
.footer hr {
  width: 100%;
  float: left;
  margin-top: 29px;
  border-top: 1px solid #999ea3;
}
.vcard {
  width: auto;
  padding-top: 16px;
  padding-top: 0;
  border: 0;
}
#services {
  float: right;
  margin-top: 17px;
}
.naviwidth {
  float: left;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 200px;
}
.logowidth {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
div.sub1 {
  margin-top: 6px;
  margin-right: 6px;
}
.navigation .sub2 {
  font-size: 13px;
  font-size: 1.3rem;
  margin-left: -1.1em;
  width: 100%;
}
.navigation .sub2 > .item > .menu {
  position: relative;
}
.navigation .sub2 > .item > .menu:after {
  position: absolute;
  top: -12px;
  padding: 1.7em 0 0 1.1em;
  right: -0.2em;
  content: '|';
  background: #fff;
  z-index: 1;
}
.navigation .sub2 > .item > .menu:before {
  position: absolute;
  width: 1.1em;
  top: -12px;
  bottom: 0;
  left: 0;
  content: '';
  background: #fff;
}
.navigation .sub2 > .exit > .menu:after {
  content: '';
}
.navigation .sub2 .menu {
  color: #444e59;
  padding: 0.9em 1.1em;
  border-top: 12px solid transparent;
}
.navigation .sub2 .menu:hover,
.navigation .sub2 .menu:focus {
  color: #39414b;
}
.navigation .sub2 .menu:active {
  color: #23282e;
}
.navigation .sub2 > .item > .menu:hover,
.navigation .sub2 > .item > .menu:focus {
  border-color: #e3e5e6;
}
.navigation .sub2 > .item > .menu:active {
  border-color: #7d848b;
}
.navigation .sub2 > .path > a.path {
  border-color: #454f59 !important;
}
.navigation .item > .sub3 {
  display: none;
}
.navigation .sub3 .menu {
  border: 0;
}
.navigation .sub3 {
  font-weight: 800;
  font-size: 13px;
  font-size: 1.3rem;
  margin-left: -1.2em;
  margin-top: 10px;
}
.navigation .sub3 .menu {
  color: #444e59;
  position: relative;
  padding: 0.4em 1.2em;
}
.navigation .sub3 .menu:after {
  position: absolute;
  right: -0.2em;
  top: 0;
  padding-top: 0.3em;
  content: '|';
  color: #444e59 !important;
}
.navigation .sub3 > .exit > .menu:after {
  display: none;
}
.footer .menu {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1;
  padding: 1.3em 0;
  padding-right: 5px;
}
.footer .sub2 {
  margin-top: 6px;
}
.footer .sub2 > .item {
  width: 16.66666667%;
}
.footer .sub2 > .item > .menu {
  padding-bottom: 0.9em;
}
.footer .sub3,
.footer .sub3 > .item {
  width: 100%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.83333333%;
  margin-left: 0.83333333%;
}
.area .part,
.area > .grid table {
  margin-right: 0.83333333%;
  margin-left: 0.83333333%;
  width: 98.33333333%;
}
.area .tiny {
  width: 48.33333333%;
}
.area > .slim {
  width: 25%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 93.33333333%;
}
.area > .slim .tiny {
  width: 93.33333333%;
}
.main > .wide {
  width: 50%;
}
.main > .wide h2,
.main > .wide .part,
.main > .wide.grid table,
.main > .wide .foot {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.main .wide .tall,
.main .wide.grid table {
  width: 96.66666667%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.side .part,
.side > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0.83333333%;
  margin-left: 0.83333333%;
  width: 98.33333333%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .tiny {
  width: 50%;
}
.base > .slim {
  width: 48.33333333%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
.base > .slim .tiny {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0.83333333%;
  margin-left: 0.83333333%;
  width: 98.33333333%;
}
.south h2,
.south .foot {
  margin-right: 1.01694915%;
  margin-left: 1.01694915%;
}
.south .part,
.south > .grid table {
  margin-right: 1.01694915%;
  margin-left: 1.01694915%;
  width: 97.96610169%;
}
.south .tiny {
  width: 47.96610169%;
}
.south > .slim {
  width: 23.33333333%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 4.28571429%;
  margin-left: 4.28571429%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 91.42857143%;
}
.south > .slim .tiny {
  width: 24.76190476%;
}
.south .slim .tall {
  width: 56.66666667%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.area {
  margin-left: -0.84745763%;
  width: 101.69491525%;
}
.main {
  margin-top: 20px;
}
div.side {
  bottom: 18px;
}
.south {
  margin-top: 10px;
}
.edge.wide.note {
  margin-top: 20px;
  margin-bottom: 20px;
}
.main .edge.wide.note:before {
  right: 0.84745763%;
  left: 0.84745763%;
}
.main > .slim.edge h2 {
  font-size: 20px;
  font-size: 2rem;
}
#view .main > .edge.slim.note h2 {
  bottom: 20px;
}
.main > .slim.edge .link {
  padding-top: 2em;
}
.side > .edge .part {
  margin-left: 1.69491525%;
}
.side .norm,
.side .pale {
  font-size: 36px;
  font-size: 3.6rem;
}
.base > .edge h2,
.base > .edge .part,
.base > .edge table .last,
.base > .seam h2,
.base > .seam .part,
.base > .seam table .last {
  padding-right: 20px;
}
.base > .edge h2,
.base > .edge .part,
.base > .edge table .first,
.base > .seam h2,
.base > .seam .part,
.base > .seam table .first {
  padding-left: 20px;
}
.base .edge.slim.grid {
  margin-top: 0;
  margin-bottom: 20px;
}
.base > .edge.grid tfoot th,
.base > .seam.grid tfoot th {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  letter-spacing: 0.025em;
}
.south > .wide .body:empty {
  margin: 0;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #454f59;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #454f59;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #2f363c;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #3a424b #24292e #24292e #3a424b;
  border-radius: 2px;
  background-color: #454f59;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(24, 28, 32, 0.75);
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #3a424b #24292e #24292e #3a424b;
  background-color: #4c5762;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #3a424b #24292e #24292e #3a424b;
  background-color: #2f363c;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-large.css.map */